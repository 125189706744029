import { styled } from '@mui/material/styles';
import { CheckboxProps } from '@mui/material/Checkbox';
import { Radio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 0,
    width: 15,
    height: 15,
    backgroundColor: 'transparent',
    outline: 'solid #E1D8CA',
    '.Mui-focusVisible &': {
        // outline: '0 auto rgba(19,124,189,.6)',
        // outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ECEAE5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#E1D8CA',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 15,
        height: 15,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:checked ~ &': {
        backgroundColor: '#E1D8CA',
    },
    'input:hover ~ &': {
        // backgroundColor: '#ECEAE5',
    },
});

// Inspired by blueprintjs
export default function ConsultingCheckBox(props: CheckboxProps) {
    return (
        <FormControlLabel value={props.value}
                          labelPlacement={'end'}
                          style={{ wordBreak: 'keep-all' }}
                          control={
                              <Radio
                                  style={{ paddingRight: '20px' }}
                                  color="default"
                                  checkedIcon={<BpCheckedIcon/>}
                                  icon={<BpIcon/>}
                                  {...props}
                              />
                          } label={props.title}></FormControlLabel>
    );
}
