import { ReactElement } from 'react';

import './image-block.scss'
import { ImageParagraph } from '../../models/paragraph';

interface ImageBlockProps {
    paragraph: ImageParagraph;
    className?: string;
}

export default function ImageBlock(props: ImageBlockProps): ReactElement {
    return (
        <div>
            {props.paragraph.url &&
                <img src={props.paragraph.url} className={`${props.className ?? ''}`} alt={'product viewer'}/>}
        </div>
    )
}
