import Fab from "@mui/material/Fab";
import Add from '../../images/add.png'

import './consulting-notice-button.scss'

export interface ConsultingNoticeButtonsProps {
    onPressed: () => void;
}

export default function ConsultingNoticeButtons(props: ConsultingNoticeButtonsProps) {
    const sxProps = {
        position: 'absolute',
        bottom: 45,
        right: 55
    };
    return (
        <div className={'consulting-notice-button'}>
            <Fab aria-label="Add"
                 component="div"
                 sx={sxProps}
                 onClick={props.onPressed}
            >
                <img src={Add} className={'consulting-notice-button-image'} alt={'consulting notice button'}/>
            </Fab>
        </div>
    );
}
