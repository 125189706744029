import { ReactElement, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';

import './consulting-notice-dialog.scss'
import ConfigurationService from '../../services/configuration-service';

import NotesMedical from '../../images/notes-medical.png';

interface ConsultingNoticeDialogProps {
    opened: boolean;
    onClosed: () => void;
}

export default function ConsultingNoticeDialog(props: ConsultingNoticeDialogProps): ReactElement {
    const [ text, setText ] = useState<string>('');

    useEffect(() => {
        async function execute() {
            const service = new ConfigurationService();
            setText(await service.getConsultingNoticeText());
        }

        execute();
    }, [ props ]);

    return (
        <div className={'survey-agreement-dialog'}>
            <Dialog open={props.opened}
                    onClose={() => props.onClosed()}
                // style={{width: '200px', marginLeft: '40%', backgroundColor: 'transparent'}}
            >
                {/*<DialogTitle>SYRS 개인 정보 수집 및 이용 동의</DialogTitle>*/}
                <DialogContent>
                    <div className={'survey-agreement-dialog-icon'}>
                        <img src={NotesMedical} alt={'consulting guide'}/>
                    </div>
                    <div className={'survey-agreement-dialog-title'}>
                        One on one Consultation
                    </div>

                    <div className={'survey-agreement-dialog-text'} dangerouslySetInnerHTML={{ __html: text }}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}
