import { doc, getDoc } from "firebase/firestore";

import { db } from '../firestore'
import { CONFIGURATIONS_COLLECTION_NAME } from '../config';

export default class ConfigurationService {
    async getPrivacyAgreementText(): Promise<string> {
        const docSnapshot = await getDoc(doc(db, CONFIGURATIONS_COLLECTION_NAME, 'privacy_agreement_text'));
        if (!docSnapshot.exists()) {
            throw new Error('No privacy agreement text.');
        }

        return docSnapshot.data().content;
    }

    async getConsultingNoticeText(): Promise<string> {
        const docSnapshot = await getDoc(doc(db, CONFIGURATIONS_COLLECTION_NAME, 'consulting_notice_text'));
        if (!docSnapshot.exists()) {
            throw new Error('No consulting notice text.');
        }

        return docSnapshot.data().content;
    }
}
