export default class KakaoTalkService {
    async sendMessage(phoneNumber: string, name: string, skinType: string) {
        const body = {
            phoneNumber: phoneNumber,
            name: name,
            skinType: skinType
        };

        await fetch(`https://survey.syrs.kr/kakaotalk?ts=${Date.now()}`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    }
}
