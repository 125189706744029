import { ReactElement } from 'react';
import './skin-layout.scss'
import Menu from '../menu/menu';
import Skin from '../../models/skin';
import TextBlock from '../text-block/text-block';
import { ImageParagraph, SkinTurnOverTextParagraph, TextParagraph } from '../../models/paragraph';
import ImageBlock from '../image-block/image-block';
import SkinTurnOverTextBlock from '../skin-turn-over-text-block/skin-turn-over-text-block';
import SubTextBlock from '../sub-text-block/sub-text-block';

export interface ProductLayoutProps {
    data: Skin;
    isConsultingMode: boolean;
}

export default function SkinLayout(props: ProductLayoutProps): ReactElement {
    const classNames: string[] = [ 'description1', 'description2', 'description3' ];
    return (
        <div className={'skin-layout'}>
            <Menu isConsultingMode={props.isConsultingMode}/>
            <div className={'skin-layout-content'}>
                <div className={'skin-layout-title'}>
                    <div>{props.data.title}</div>
                </div>
                {
                    props.data.paragraphs.map((x, i) => {
                        if (x.type === 'text') {
                            const textParagraph = x as TextParagraph;
                            return <TextBlock title={textParagraph.title} content={textParagraph.content}/>;
                        } else if (x.type === 'subText') {
                            return <SubTextBlock paragraph={x as TextParagraph} className={classNames[i]}/>;
                        } else if (x.type === 'image') {
                            return <ImageBlock paragraph={x as ImageParagraph} className={'skin-layout-image'}/>;
                        } else if (x.type === 'skinTurnOverText') {
                            return <SkinTurnOverTextBlock paragraph={x as SkinTurnOverTextParagraph}/>;
                        } else {
                            return <div>unknown type</div>
                        }
                    })
                }
            </div>
        </div>
    );
}
