import React, { ReactElement, useState } from 'react';

import './password-page.scss'
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function PasswordPage(): ReactElement {
    const [ password, setPassword ] = useState<String>('');
    const navigate = useNavigate();

    function onLoginPressed() {
        if (password !== 'syrs1234!') {
            alert('비밀번호가 틀렸습니다.');
            return;
        }

        navigate('/home');
    }

    return (
        <div className={'password-page'}>
            <div className={'password-page-layout'}>
                <div className={'password-page-password-field'}>
                    <TextField label={'Password'} fullWidth={true} type={'password'}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}>{password}</TextField>
                </div>
                <div className={'password-page-button-field'}>
                    <Button variant="contained" color={'success'} onClick={onLoginPressed}>Login</Button>
                </div>

            </div>

        </div>
    );
}
