import React from 'react';
import './App.scss';
import ProductPage from './pages/product-page/product-page';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SkinPage from './pages/skin/skin-page';
import SurveyPage from './pages/survey-page/survey-page';
import ResultPage from './pages/result-page/result-page';
import ConsultingPage from './pages/consulting-page/consulting-page';
import WelcomePage from './pages/welcom-page/welcom-page';
import PasswordPage from './pages/password-page/password-page';

function App() {


    return (
        <div className="App">
            {/*<ConsultingPage></ConsultingPage>*/}
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<PasswordPage/>}></Route>
                    <Route path={'/home'} element={<WelcomePage/>}></Route>
                    <Route path={'/product'} element={<ProductPage/>}></Route>
                    <Route path={'/consulting'} element={<ConsultingPage/>}></Route>
                    <Route path={'/skin'} element={<SkinPage/>}></Route>
                    <Route path={'/survey'} element={<SurveyPage/>}></Route>
                    <Route path={'/result/:type'} element={<ResultPage/>}></Route>
                    {/*<Route path={'/develop/clone'} element={<DbCloningPage/>}></Route>*/}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
