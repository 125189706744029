import { collection, doc, getDoc, getDocs } from "firebase/firestore";

import { db } from '../firestore'
import { Result } from '../models/result';
import { ConsultingResultType } from '../models/answer';
import { RESULTS_COLLECTION_NAME } from '../config';

export default class ResultService {
    async list(): Promise<Result[]> {
        const querySnapshot = await getDocs(collection(db, 'results'));
        querySnapshot.forEach((doc) => {

        });
        return querySnapshot
            .docs
            .map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                } as Result;
            });
    }

    private typeIdMapping: Map<ConsultingResultType, string> = new Map([
        ['A', 'type_a'],
        ['B', 'type_b'],
        ['C', 'type_c'],
        ['D', 'type_d'],
        ['E', 'type_e'],
    ]);

    async getType(type: ConsultingResultType): Promise<Result> {
        return this._getType(this.typeIdMapping.get(type)!);
    }

    private async _getType(id: string): Promise<Result> {
        const docSnapshot = await getDoc(doc(db, RESULTS_COLLECTION_NAME, id));
        if (!docSnapshot.exists()) {
            throw new Error('No result.');
        }

        return {
            id: docSnapshot.id,
            ...docSnapshot.data()
        } as Result;
    }
}
