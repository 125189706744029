import React, { ReactElement, useEffect, useState } from 'react';

import './text-input-field.scss'


export interface TextInputFieldProps {
    type?: string;
    label: string;
    hint?: string;
    value?: any;
    onEntered: (text: string) => void;
}

export default function TextInputField(props: TextInputFieldProps): ReactElement {
    const [ value, setValue ] = useState<string>('');

    useEffect(() => {
        setValue(props.value ?? '');
    }, [ props.value ]);

    return (
        <div className={'text-input-field'}>
            <label>{props.label}</label>
            <input type={props.type ?? 'text'}
                   value={value}
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                       setValue(e.target.value);
                       props.onEntered(e.target.value)
                   }}/>
            {props.hint && <p className={'text-input-field-hint'}>{props.hint}</p>}
        </div>
    )
}
