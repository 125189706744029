import './birth-day-selector.scss'
import React, { ReactElement, useEffect, useState } from 'react';
import { getLastDate } from '../../util/dates';

export interface BirthDaySelectorProps {
    label: string;
    value?: Date;
    onSelected: (d: Date) => void;
}

export default function BirthDaySelector(props: BirthDaySelectorProps): ReactElement {
    const now = new Date();
    const yearNow = now.getFullYear();
    const monthNow = now.getMonth();
    const dayNow = now.getDate();

    const [ selectedDate, setSelectedDate ] = useState<Date>(props.value ?? new Date());

    useEffect(() => {
        setSelectedDate(props.value ?? new Date());
    }, [ props.value ]);

    function notifyChanges(d: Date) {
        setSelectedDate(d);
        props.onSelected(d);
    }

    function onYearSelectionChanged(event: React.ChangeEvent<HTMLSelectElement>) {
        const targetYear = parseInt(event.target.value);
        const d = new Date(targetYear, selectedDate.getMonth(), selectedDate.getDate())
        notifyChanges(d);
    }

    function onMonthSelectionChanged(event: React.ChangeEvent<HTMLSelectElement>) {
        const targetMonth = parseInt(event.target.value);
        const d = new Date(selectedDate.getFullYear(), targetMonth, (selectedDate.getMonth() === targetMonth) ? selectedDate.getDate() : 1);
        notifyChanges(d);
    }

    function onDaySelectionChanged(event: React.ChangeEvent<HTMLSelectElement>) {
        const targetDay = parseInt(event.target.value);
        const d = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), targetDay);
        notifyChanges(d);
    }

    const lastDay = getLastDate(selectedDate).getDate();
    const actualDay = (selectedDate.getFullYear() === yearNow && selectedDate.getMonth() === monthNow) ? dayNow : lastDay;

    return (
        <div className={'birth-day-selector'}>
            <label>{props.label}</label>
            <div className={'controls'}>
                <select className={'year-selector'} onChange={onYearSelectionChanged}>
                    {
                        [ ...Array(yearNow - 1960 + 1) ].map((_, i) =>
                            <option key={yearNow - i}
                                    value={yearNow - i}
                                    selected={selectedDate.getFullYear() === (yearNow - i)}>{yearNow - i}</option>)
                    }
                </select>

                <select className={'month-selector'} onChange={onMonthSelectionChanged}>
                    {
                        selectedDate.getFullYear() === yearNow &&
                        [ ...Array(monthNow + 1) ].map((_, i) =>
                            <option key={i}
                                    value={i}
                                    selected={i === selectedDate.getMonth()}>{i + 1}</option>)
                    }
                    {
                        selectedDate.getFullYear() !== yearNow &&
                        [ ...Array(12) ].map((_, i) =>
                            <option key={i}
                                    value={i}
                                    selected={i === selectedDate.getMonth()}>{i + 1}</option>)

                    }
                </select>

                <select className={'day-selector'} onChange={onDaySelectionChanged}>
                    {
                        [ ...Array(actualDay) ].map((_, i) =>
                            <option key={i + 1}
                                    value={i + 1}
                                    selected={(i + 1) === selectedDate.getDate()}>{i + 1}</option>)
                    }
                </select>
            </div>

        </div>
    );
}
