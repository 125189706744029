import React, { ReactElement } from 'react';

import SurveyHeader from '../survey-header/survey-header';
import { Result } from '../../models/result';

import './survey-result-layout.scss'
import SolutionCard from '../solution-card/solution-card';

export interface SurveyResultLayoutProps {
    result: Result;
    closable: boolean;
    onClosed?: () => void;
}

export default function SurveyResultLayout(props: SurveyResultLayoutProps): ReactElement {
    return (
        <div className={'survey-result-layout'}>
            <SurveyHeader closable={props.closable} onClosed={props.onClosed}/>
            <div className={'survey-result-layout-top-notice'}>Report consultation</div>
            <div className={'survey-result-layout-title'}>[ {props.result.typeName} - {props.result.title} ]</div>
            <div className={'survey-result-layout-sub-title'}>{props.result.subTitle}</div>
            <div className={'survey-result-layout-description'}
                 dangerouslySetInnerHTML={{ __html: props.result.description }}/>
            <div className={'survey-result-layout-tags'}>
                {
                    props.result.tags.map(tag =>
                        <div key={tag}
                             className={'survey-result-layout-tags-item'}>{tag}</div>)
                }
            </div>
            <div className={'survey-result-layout-solutions-title'}>{props.result.typeName} Solution.</div>
            <div className={'survey-result-layout-solutions-content'}>
                {
                    props.result.solutions.map((solution, index) =>
                        <div key={index}
                             className={'survey-result-layout-solutions-content-item'}>
                            <SolutionCard prefix={`Solution. ${index + 1}`}
                                          solution={solution}/>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
