import { ReactElement } from 'react';

import './skin-turn-over-text-block.scss'
import { SkinTurnOverTextParagraph } from '../../models/paragraph';
import TextBlock from '../text-block/text-block';

interface SkinTurnOverTextBlockProps {
    paragraph: SkinTurnOverTextParagraph
    className?: string;
}

export default function SkinTurnOverTextBlock(props: SkinTurnOverTextBlockProps): ReactElement {
    return (
        <div className={`skin-turn-over-text-block ${props.className ?? ''}`}>

            <TextBlock title={props.paragraph.titles.left}
                       content={props.paragraph.contents.left}
                       className={'skin-turn-over-text-content skin-turn-over-text-content-left'}/>

            <TextBlock title={props.paragraph.titles.right}
                       content={props.paragraph.contents.right}
                       className={'skin-turn-over-text-content skin-turn-over-text-content-right'}/>
        </div>
    )
}
