import React, { ReactElement } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

import './page-slider.scss'


export interface PageSliderProps {
    children: any[];
}

export default function PageSlider(props: PageSliderProps): ReactElement {
    return (
        <Swiper modules={[Pagination, Mousewheel, Navigation, Scrollbar, EffectFade]}
                slidesPerView={1}
                // threshold={2}
                effect={'fade'}
                spaceBetween={0}
                navigation={true}
                loop={true}
                mousewheel={{ forceToAxis: true, sensitivity: 0.1, releaseOnEdges: true }}
                // pagination={{ clickable: true }}
        >
            {
                props.children.map(x => <SwiperSlide>{x}</SwiperSlide>)
            }
        </Swiper>
    );
}
