// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyABcV2VS2IYv0oZbAal60RDV6uPgXT2IJE",
    authDomain: "syrs-9e071.firebaseapp.com",
    projectId: "syrs-9e071",
    storageBucket: "syrs-9e071.appspot.com",
    messagingSenderId: "92378431573",
    appId: "1:92378431573:web:aa8a6fe81df824e95bd4dc",
    measurementId: "G-D0DP9PSEYJ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// const analytics = getAnalytics(app);
