import { collection, getDocs } from "firebase/firestore";

import { db } from '../firestore'
import Product from '../models/product';
import { PRODUCTS_COLLECTION_NAME } from '../config';

export default class ProductService {
    async list(): Promise<Product[]> {
        const querySnapshot = await getDocs(collection(db, PRODUCTS_COLLECTION_NAME));
        return querySnapshot
            .docs
            .map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                } as Product;
            })
            .sort((a, b) => a.order - b.order);
    }
}
