import { addDoc, collection } from "firebase/firestore";

import { db } from '../firestore'
import Answer, { CreatingAnswer } from '../models/answer';
import { ANSWERS_COLLECTION_NAME } from '../config';

export default class AnswerService {
    async write(answer: CreatingAnswer): Promise<Answer> {
        const docRef = await addDoc(collection(db, ANSWERS_COLLECTION_NAME), answer);
        return {
            id: docRef.id,
            ...answer,
        } as Answer;
    }
}
