import { ReactElement } from 'react';

import './sub-text-block.scss'
import { TextParagraph } from '../../models/paragraph';

interface SubTextBlockProps {
    paragraph: TextParagraph
    className?: string;
}

export default function SubTextBlock(props: SubTextBlockProps): ReactElement {
    return (
        <div className={`sub-text-block ${props.className ?? ''}`}>
            <div className={'content'} dangerouslySetInnerHTML={{ __html: props.paragraph.content }}/>
        </div>
    )
}
