import React, { ReactElement } from 'react';
import closeIcon from '../../images/close.svg';

import './survey-header.scss';
import logo from '../../images/logo.png';

export interface SurveyHeaderProps {
    closable: boolean;
    onClosed?: () => void;
}

export default function SurveyHeader(props: SurveyHeaderProps): ReactElement {
    return (
        <div className={'survey-header'}>
            <div><img src={logo} alt={'logo'} height={22}/></div>
            <div className={'survey-header-space'}></div>
            {props.closable && <div><img src={closeIcon} alt={'Close'} onClick={props.onClosed}/></div> }
        </div>
    );
}
