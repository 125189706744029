import { ReactElement } from 'react';

import './text-block.scss'

interface TextBlockProps {
    title?: string;
    content?: string;
    className?: string;
}

export default function TextBlock(props: TextBlockProps): ReactElement {
    return (
        <div className={`text-block ${props.className ?? ''}`}>
            {props.title &&
                <div className={'text-block-title'} dangerouslySetInnerHTML={{ __html: props.title }}/>}

            {props.content &&
                <div className={'text-block-content'} dangerouslySetInnerHTML={{ __html: props.content }}/>}
        </div>
    )
}
