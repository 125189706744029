import { collection, getDocs } from "firebase/firestore";

import { db } from '../firestore'
import Skin from '../models/skin';
import { SKINS_COLLECTION_NAME } from '../config';

export default class SkinService {
    async list(): Promise<Skin[]> {
        const querySnapshot = await getDocs(collection(db, SKINS_COLLECTION_NAME));
        return querySnapshot
            .docs
            .map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                } as Skin;
            })
            .sort((a, b) => a.order - b.order);
    }
}
