import { ReactElement } from 'react';

import './menu.scss'
import { Link, useLocation } from 'react-router-dom';

export interface MenuProps {
    isConsultingMode: boolean;
}

export default function Menu(props: MenuProps): ReactElement {
    const location = useLocation();
    return (
        <div className={'menu'}>
            <ul>
                <li><Link to={props.isConsultingMode ? '/consulting' : '/product'}
                          className={(location.pathname === '/product' || location.pathname === '/consulting') ? 'activated-link' : ''}>Product</Link>
                </li>
                <li><Link to={`/skin?c=${props.isConsultingMode}`}
                          className={location.pathname === '/skin' ? 'activated-link' : ''}>Skin</Link>
                </li>
            </ul>
        </div>
    )
}
